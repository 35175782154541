import React from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css";
// import "../styles/ImageSlider.css";
import { Carousel } from "react-responsive-carousel";

function DiscountBanner() {
  const images = [
    {
      productImageUrl:
        "https://res.cloudinary.com/dfagcn631/image/upload/v1735630436/2_wb1uac.png",
      productName: "NewYearOffer",
    },
    {
      productImageUrl:
        "https://res.cloudinary.com/dfagcn631/image/upload/v1735630204/1_lxq1km.png",
      productName: "First100Customer",
    },
    {
      productImageUrl:
        "https://res.cloudinary.com/dfagcn631/image/upload/v1735630493/3_ebcdlr.png",
      productName: "Win an Iphone",
    },
  ];

  return (
    <div
      className="imageSliderContainer"
      style={{
        overflow: "hidden",
        borderRadius: "20px",
        marginTop: "40px",
      }}
    >
      <Carousel
        autoPlay
        infiniteLoop
        interval={4000}
        showArrows={false}
        showThumbs={false}
        showStatus={false}
        stopOnHover={false}
        transitionTime={500}
      >
        {images.map((image, index) => (
          <div
            key={index}
            className="sliderImage"
            style={{ height: "100%", display: "flex", alignItems: "center" }}
          >
            <img
              src={image.productImageUrl}
              alt={image.productName}
              style={{ maxHeight: "100%", objectFit: "cover" }}
            />
          </div>
        ))}
      </Carousel>
    </div>
  );
}

export default DiscountBanner;
