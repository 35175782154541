import React from "react";
import "../styles/footerPages.css"; // Importing CSS for styling
import StoryBanner from "./StoryBanner";

function AboutUs() {
  return (
    <>
      <div id="mainContainerStoryBannerInt">
        <StoryBanner />
      </div>
      <div id="MainContainer">
        <h1 id="mainHead">Welcome to One Snack</h1>
        <p id="content">
          Your ultimate destination for healthy and innovative snacks that
          combine taste and nutrition in every bite. We specialize in
          transforming traditional ingredients like{" "}
          <strong>makhanas (foxnuts)</strong> and <strong>premium nuts</strong>{" "}
          into wholesome snacks, offering a modern twist to your everyday
          munching.
        </p>
        <h2 id="sub">Our Mission</h2>
        <p id="content">
          At One Snack, our mission is to provide you with guilt-free snacking
          options that support your active lifestyle. Whether you’re craving a
          light snack between meals or looking for a nutritious alternative, our
          range of snacks—like our <strong>roasted makhanas</strong>,{" "}
          <strong>protein-packed nuts</strong>, and flavorful spice blends—are
          designed to keep you satisfied without compromising on health.
        </p>
        <h2 id="sub">Quality Ingredients & Innovation</h2>
        <p id="content">
          Our snacks are carefully crafted using high-quality ingredients,
          roasted in olive oil (not fried), and seasoned with innovative spices
          to create a delightful snacking experience. Each bite is packed with{" "}
          <strong>protein, antioxidants</strong>, and essential nutrients,
          making our snacks a perfect choice for all age groups.
        </p>
        <h2 id="sub">Commitment to Excellence</h2>
        <p id="content">
          We take pride in our meticulous sourcing methods, cutting-edge
          production facilities, and dedication to delivering the best of both
          taste and nutrition. At One Snack, we are committed to being the go-to
          brand for healthy snacks in India, offering products that are not only
          delicious but also contribute to a balanced diet.
        </p>
        <h2 id="sub">Join the Revolution</h2>
        <p id="content">
          Join the healthy snacking revolution with One Snack! Discover our wide
          range of nutritious, low-calorie snacks that will leave you craving
          more while helping you maintain a healthy lifestyle.
        </p>
      </div>
    </>
  );
}

export default AboutUs;
