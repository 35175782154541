import React, { useEffect, useState } from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import "../styles/ImageSlider.css";
import { Carousel } from "react-responsive-carousel";
import axios from "axios";

function Shimmer() {
  return (
    <div className="shimmer-container">
      <div className="shimmer"></div>
    </div>
  );
}

function ImageSlider() {
  const [images, setImages] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchImages = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_BACKEND_URL}/api/home/`
        );
        setImages(response.data);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching images:", error);
        setLoading(false);
      }
    };

    fetchImages();
  }, []);

  if (loading) {
    return (
      <div style={{ padding: "20px" }}>
        <Shimmer />
      </div>
    );
  }

  return (
    <div className="imageSliderContainer">
      <Carousel
        autoPlay
        infiniteLoop
        interval={5000}
        showArrows={false}
        showThumbs={false}
        showStatus={false}
        stopOnHover={false}
        transitionTime={500}
      >
        {images.map((image, index) => (
          <div key={index} className="sliderImage">
            <img src={image.productImageUrl} alt={image.productName} />
          </div>
        ))}
      </Carousel>
    </div>
  );
}

export default ImageSlider;
