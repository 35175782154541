import React, { useState, useEffect } from "react";
import PromotionalBanner from "./PromotionalBanner";
import ImageSlider from "./ImageSlider";
import TestimonialPage from "./TestimonialPage";
// import StarRate from "./StarRate";
import "../styles/Body.css";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import DiscountBanner from "./DiscountBanner";

function Body() {
  const navigate = useNavigate();
  const [products, setProducts] = useState([]);
  const [loadingStates, setLoadingStates] = useState({});
  const [isLoading, setIsLoading] = useState(true); // Track loading state for shimmer

  const handleProductClick = (productId) => {
    navigate(`/product/${productId}`);
  };

  const handleAddToCart = async (products_id) => {
    await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/cart`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      body: JSON.stringify({ products_id, quantity: 1 }),
    });
  };

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_BACKEND_URL}/api/allProducts`
        );
        const data = await response.json();

        const filterCategory = (category) =>
          data
            .filter((product) =>
              product.productName.toLowerCase().includes(category)
            )
            .slice(0, 4);

        const makhanaProducts = filterCategory("makhana");
        const dipsProducts = filterCategory("dips");
        const chipsProducts = filterCategory("chips");

        const filteredProducts = [
          ...makhanaProducts,
          ...dipsProducts,
          ...chipsProducts,
        ];
        setProducts(filteredProducts);
        setIsLoading(false); // Stop shimmer once data is loaded
      } catch (error) {
        console.error("Error fetching products:", error);
        setIsLoading(false); // Stop shimmer even if there's an error
      }
    };

    fetchProducts();
  }, []);

  const handleClick = (product) => {
    const token = localStorage.getItem("token");
    if (!token) {
      toast.error("Login/Signup first", {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
      navigate("/login");
      return;
    }
    setLoadingStates((prevStates) => ({
      ...prevStates,
      [product.products_id]: true,
    }));

    setTimeout(() => {
      handleAddToCart(product._id);
      setLoadingStates((prevStates) => ({
        ...prevStates,
        [product.products_id]: false,
      }));
    }, 1000);
    toast.success("Added to Cart!", {
      position: "top-center",
      autoClose: 1000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "colored",
    });
  };

  return (
    <div>
      <PromotionalBanner />
      <ImageSlider />
      {/* <DiscountBanner /> */}
      <div className="mainBoxContainerAllProducts9">
        {isLoading || products.length <= 0
          ? // Render shimmer UI while loading or if no products
            Array.from({ length: 6 }).map((_, index) => (
              <div key={index} className="shimmer-product-card">
                <div className="shimmer-product-image shimmer"></div>
                <div className="shimmer-product-name shimmer"></div>
                <div className="shimmer-product-price shimmer"></div>
                <div className="shimmer-product-add-to-cart shimmer"></div>
              </div>
            ))
          : products.map((product) => (
              <div key={product.products_id} className="productCard1 card-1">
                <div className="productImageContainer9">
                  <img
                    src={product.productImageUrl}
                    alt={product.productName}
                    onClick={() => handleProductClick(product._id)}
                    style={{ cursor: "pointer" }}
                  />
                </div>
                <div
                  className="productNameContainer9"
                  onClick={() => handleProductClick(product._id)}
                  style={{ cursor: "pointer" }}
                >
                  <span>{product.productName}</span>
                </div>
                <div className="productPriceContainer9">
                  <span>Rs. {product.productPrice}</span>
                </div>
                <div className="productPriceContainer9">
                  {/* <StarRate
                    userId={localStorage.getItem("id")}
                    productId={product._id}
                    productModel="allProducts"
                  /> */}
                </div>
                <div className="productAddToCartButton">
                  <button
                    className={
                      loadingStates[product.products_id]
                        ? "button loading"
                        : "button"
                    }
                    onClick={() => handleClick(product)}
                    disabled={loadingStates[product.products_id]}
                  >
                    <span>Add to cart</span>
                    <div className="cart">
                      <svg viewBox="0 0 36 26">
                        <polyline points="1 2.5 6 2.5 10 18.5 25.5 18.5 28.5 7.5 7.5 7.5"></polyline>
                        <polyline points="15 13.5 17 15.5 22 10.5"></polyline>
                      </svg>
                    </div>
                  </button>
                </div>
              </div>
            ))}
      </div>

      <TestimonialPage />
      <div id="mainDiscountContainerBanner">
        <DiscountBanner />
      </div>
    </div>
  );
}

export default Body;
