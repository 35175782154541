import React from "react";

const StoryBanner = () => {
  return (
    <div id="mainStoryContainer">
      <div id="insideMainStoryContainer">
        <img
          src="https://res.cloudinary.com/dfagcn631/image/upload/v1735630870/3_lse8vb.png"
          alt="story"
        />
      </div>
    </div>
  );
};

export default StoryBanner;
